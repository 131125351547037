import { ParentComponent } from "solid-js";
import { Motion } from "solid-motionone";

export const AccountPanel: ParentComponent<{ class?: string }> = (props) => (
  <Motion.div
    class={props.class}
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10, transition: { duration: 0.175 } }}
    transition={{ duration: 0.2, easing: "ease-in-out" }}
  >
    {props.children}
  </Motion.div>
);
